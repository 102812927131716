/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Global Styles */

:root {
    --primary-color: #C70C18;
    --dark-color: #343a40;
    --light-color: #f4f4f4;
    --danger-color: #dc3545;
    --success-color: #28a745;
    --background-color: #232323;
    text-align: center;

}

li.country {
    color: black;
}

@media only screen and (max-width: 600px) {

    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        font-size: .5rem;
    }


}

@media only screen and (min-width: 700px) {

    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        font-size: 1.5rem;
    }


}

#delete-text {
    color: var(--primary-color);
}

#delete-text:hover {
    color: grey;
}

.content-container {
    padding-top: 70px;
    /* Adjust to the height of your navbar */
}

.color-nav {
    background-color: var(--primary-color);
    color: white;
}

.navbar-light .navbar-brand {
    color: white;
}

#basic-navbar-nav>div>a {
    color: white;
}

#basic-nav-dropdown {
    color: white;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body,
#team-members,
#cover {
    /* font-family: 'Raleway', sans-serif;*/
    font-family: 'Oswald',
        sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    background-color: var(--background-color);
    color: white;
    text-align: center;
    max-width: 100%;
    overflow-x: hidden;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

}

@media only screen and (max-width: 768px) {
    #cover {
        background-attachment: scroll;
        /* Prevents the image from looking stretched on small screens */
        background-size: auto 100%;
        /* Adjusts the size to maintain aspect ratio */
        background-position: top center;
        /* Positions the image */
    }
}

@media only screen and (max-width: 480px) {
    #cover {
        background-size: auto 100%;
        /* Ensures background fits well vertically on small screens */
    }
}

.custom-link {
    color: #C70C18;
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0 15px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.custom-link:hover {
    color: #FFFFFF;
}

.card-body {
    background-color: var(--background-color);

}

.card-header {
    background-color: var(--primary-color);
}

.card {
    background-color: none;
}

#card-details,
#expiration,
#cvc {
    background-color: white;
    width: 200px;
    height: 20px;


}

.social-icon {
    font-size: 1.5rem;
    color: #C70C18;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #ffffff;
    /* White hover color */
}

.blog-container {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.blog-post {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    background-color: var(--light-color);
    color: var(--dark-color);
}

.blog-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.blog-content-preview {
    font-size: 1rem;
    color: var(--dark-color);
}

.blog-link {
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
}

.blog-link:hover {
    color: var(--success-color);
}

.text-justify {
    text-align: justify;
}